.loading-loader {
    --width: 200px;
    --height: 200px;
    background: var(--backgroundColor);
    border-radius: 50%;
    position: relative;

    width: calc(var(--width) + 50px);
    height: calc(var(--height) + 50px);

    @mixin centre($axis: "both") {
        position: absolute;

        @if $axis=="y" {
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }

        @if $axis=="x" {
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
        }

        @if $axis=="both" {
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-51%, -50%);
            -moz-transform: translate(-51%, -50%);
            -ms-transform: translate(-51%, -50%);
            -o-transform: translate(-51%, -50%);
            transform: translate(-51%, -50%);
        }
    }

    @mixin font-size($sizeValue: 1.6, $lineHeight: 2.4) {
        font-size: ($sizeValue * 10) + px;
        font-size: $sizeValue + rem;
        line-height: ($lineHeight * 10) + px;
    }

    @mixin animation($animate...) {
        $max: length($animate);
        $animations: '';

        @for $i from 1 through $max {
            $animations: #{$animations + nth($animate, $i)};

            @if $i < $max {
                $animations: #{$animations + ", "};
            }
        }

        -webkit-animation: $animations;
        -moz-animation: $animations;
        -o-animation: $animations;
        animation: $animations;
    }


    @mixin keyframes($animationName) {
        @-webkit-keyframes #{$animationName} {
            @content;
        }

        @-moz-keyframes #{$animationName} {
            @content;
        }

        @-o-keyframes #{$animationName} {
            @content;
        }

        @keyframes #{$animationName} {
            @content;
        }
    }

    @include keyframes(outerRotate1) {
        0% {
            transform: translate(-50%, -50%) rotate(0)
        }

        100% {
            transform: translate(-50%, -50%) rotate(360deg)
        }
    }

    @include keyframes(outerRotate2) {
        0% {
            transform: translate(-50%, -50%) rotate(0)
        }

        100% {
            transform: translate(-50%, -50%) rotate(-360deg)
        }
    }

    @include keyframes(textColour) {
        0% {
            color: #fff;
        }

        100% {
            color: #3BB2D0;
        }
    }

    .e-loadholder {
        @include centre();
        width: var(--width);
        height: var(--height);
        border: 5px solid #1B5F70;
        border-radius: 120px;
        box-sizing: border-box;

        &:after {
            @include centre;
            content: " ";
            display: block;
            background: var(--backgroundColor);
            transform-origin: center;
            z-index: 0;
            width: 100px;
            height: 200px;
            border-radius: 25px;
            @include animation('outerRotate2 6s infinite linear');
        }

        .m-loader {
            @include centre();
            width: 150px;
            height: 150px;
            //color: #888;
            text-align: center;
            border: 5px solid lighten(#1B5F70, 15%);
            border-radius: 50%;
            box-sizing: border-box;
            z-index: 20;
            text-transform: uppercase;

            &:after {
                @include centre;
                content: " ";
                display: block;
                background: var(--backgroundColor);
                transform-origin: center;
                z-index: -1;
                border: 1px solid var(--backgroundColor);
                width: 100px;
                height: 106%;
                @include animation('outerRotate1 15s infinite linear');
            }

            .e-text {
                @include font-size(1.4, 13);
                @include centre();
                @include animation('textColour 1s alternate linear infinite');
                display: block;
                width: 140px;
                height: 140px;
                text-align: center;
                border: 5px solid lighten(#1B5F70, 25%);
                border-radius: 70px;
                box-sizing: border-box;
                z-index: 20;

                &:before,
                &:after {
                    @include centre;
                    content: " ";
                    display: block;
                    background: var(--backgroundColor);
                    transform-origin: center;
                    z-index: -1;
                }

                &:before {
                    width: 110%;
                    height: 40px;
                    @include animation('outerRotate2 4s infinite linear');
                }

                &:after {
                    width: 40px;
                    height: 110%;
                    @include animation('outerRotate1 8s infinite linear');
                }
            }
        }
    }

}
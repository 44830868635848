.slate {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .centerer {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: all 0.3s;
    transform-style: preserve-3d;

    .slate-content {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
    }
  }

  .background {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

html,
body {
  position: fixed;
  overflow-y: hidden;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: var(--textColor);
}

* {
  box-sizing: border-box;
}

.drawer-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.help {
  --buttonSize: 50px;

  &__items {
    .help-item {
      padding: 5px;
      display: flex;
      align-items: center;

      &__icon {
        margin: 0 10px 0 0 !important;
      }

      &--small {
        --buttonSize: 25px;

        .button i {
          margin: 0;
          font-size: 0.9rem;
        }
      }
    }
  }
}

.end-session {
  padding: 1rem;
  z-index: 10000000000;
  background: red;
  position: fixed;
  position: device-fixed;
  left: 0;
  top: 0;

  button {
    margin-bottom: 2rem;

    &.end-session-button {
      margin-top: 2rem;
    }
  }
}

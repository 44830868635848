.heatmap {
  position: relative;

  .range {
    position: absolute;
    z-index: 2;

    left: 0;
    bottom: 0;

    .block {
      width: 10px;
      height: 1px;
    }
  }

  &-select {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;

    &-info {
      position: absolute;
      left: 0;
      background: rgba(0, 0, 0, 0.75);
      padding: 0.5rem;
      width: 100%;
      z-index: 1000;
      font-size: 12px;

      & div {
        display: inline-block;
        margin: 0 2rem 0.5rem 0;
      }
    }
  }

  &-selected-drawing {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
}

@font-face {
    font-family: "ikonikat";
    src: url("./ikonikat.eot?852e0b89e2e0d7293467d5c612440933?#iefix") format("embedded-opentype"),
url("./ikonikat.woff2?852e0b89e2e0d7293467d5c612440933") format("woff2"),
url("./ikonikat.woff?852e0b89e2e0d7293467d5c612440933") format("woff"),
url("./ikonikat.ttf?852e0b89e2e0d7293467d5c612440933") format("truetype"),
url("./ikonikat.svg?852e0b89e2e0d7293467d5c612440933#ikonikat") format("svg");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: ikonikat !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-logo:before {
    content: "\f101";
}
.icon-mode0:before {
    content: "\f102";
}
.icon-mode1:before {
    content: "\f103";
}
.icon-mode2:before {
    content: "\f104";
}
.icon-multitouch:before {
    content: "\f105";
}
